import React from 'react'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'

import Typography from '@material-ui/core/Typography'

import { useStaticQuery, graphql } from 'gatsby'

import { useThemeContext } from 'context/ThemeContext'
import Link from 'components/foundations/Link/Link'
import CustomImage from 'components/foundations/Image/CustomImage'
import SEO from 'components/elements/SEO/SEO'

export default function MedicineList() {
  const MedicineQuery = Object.values(
    useStaticQuery(graphql`
      query MedicineLQuery {
        allSitePage(filter: { path: { regex: "/^/servizi/medicina/" } }) {
          nodes {
            path
          }
        }
      }
    `).allSitePage.nodes,
  )

  const productURIs = MedicineQuery.map((paths) => Object.values(paths).toString())

  function splitName(string) {
    return string.split('/')[3]
  }

  const { isMobile } = useThemeContext()

  return (
    <>
      <SEO title="Medicina" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <section className="consulting closer">
        <h3 className="title">Medicina</h3>
        <CustomImage filename={'medicine.png'} />
        <div className="consulting-grid">
          <div className="consulting-grid-list">
            <p style={{ marginBottom: '0.5rem' }}>CATEGORIE</p>
            <div className="separator" />
            <GridList cols={isMobile ? 1 : 1} cellHeight="auto" className="gridListConsulting">
              {productURIs.map((productURL) => (
                <GridListTile className="single-consulting" key={splitName(productURL)}>
                  <Link to={productURL}>
                    <Typography className="consulting-info" component="p">
                      {splitName(productURL).replace(/-/g, ' ')}
                    </Typography>
                  </Link>
                  <hr />
                </GridListTile>
              ))}
            </GridList>
          </div>
          <div className="consulting-grid-content">
            <p className="consulting-content-text">
              <strong>
                AI GIORNI NOSTRI, LA PARTE PEGGIORE DEL LAVORO E’ CIO’ CHE CAPITA ALLA GENTE QUANDO SMETTE DI LAVORARE.
              </strong>
              <br />
              Gilbert Keith Chesterton
              <br /> <br />
              <b>
                ING GROUP offre la sua competenza a favore della salute dei lavoratori e la tranquillità del datore di
                lavoro assicurando:
              </b>
              <br /> <br />• Assunzione di Incarico di <b>MEDICO COMPETENTE</b> e <b>COORDINATORE</b>
              <br />• Gestione della <b>SORVEGLIANZA SANITARIA</b> dei Lavoratori sia presso la propria sede che
              direttamente in azienda
              <br />• Gestione delle <b>CARTELLE CLINICHE</b> e della relativa sicurezza dei dati
              <br />• Esecuzione di <b>VISITE MEDICHE PREVENTIVE</b>
              <br />• Esecuzione di <b>VISITE MEDICHE PERIODICHE</b> e <b>DI RIENTRO</b> da infortuni e malattie
              <br />• Esecuzione dei <b>SOPRALLUOGHI PERIODICI</b> delle sedi lavorative
              <br />• Esecuzione di <b>VISITE MEDICHE PRE ESPATRIO</b>
              <br />• Esecuzione di <b>ESAMI DI LABORATORIO</b>
              <br />
              • Assistenza in caso di verifica da parte dell’organo di vigilanza preposto
              <br /> <br />
              Disponiamo di 50 centri medici convenzionati in Italia a copertura del servizio su tutto il territorio
              nazionale
            </p>
          </div>
        </div>
      </section>
    </>
  )
}
